import { Guid } from "guid-typescript";
import { AuthService } from "./AuthService";
import { AppStore } from "../stores/AppStore";

class impGetAlerts implements GetAlerts {
  constructor(
    hitlimit: number,
    alert_data: Alert[],
    success: boolean,
    code: number,
    timefilter: number,
  ) {
    this.hitlimit = hitlimit;
    this.alert_data = alert_data;
    this.success = success;
    this.code = code;
    this.timefilter = timefilter;
  }
  code: number;
  hitlimit: number;
  alert_data: Alert[];
  success: boolean;
  timefilter: number;
}
class impMediaResult implements MediaResult {
  alert_id!: string;
  url!: string;
  video_file!: string;
  b64string!: string;
}
export interface AuthResult {
  access_token: string;
  refresh_token: string;
  success: boolean;
  msg: string;
  code: number;
}
export interface CheckVersionResult {
  min_alert_gui_ver: string;
  alert_gui_ver_logout_required: boolean;
}
export interface Alert {
  alert_type: string;
  class_label: string;
  hub_id_str: string;
  hub_location: string;
  inference_classes: string[];
  inference_probabilities: number[];
  message: string;
  probability: number;
  review_status: string;
  review_user_username: string;
  improve_accuracy_flag: boolean;
  improve_accuracy_user_id: number;
  improve_accuracy_username: string;
  improve_accuracy_timestamp: number;
  sensor_event: number;
  sensor_human_name: string;
  sensor_id_str: string;
  site_id: number;
  track_id: string;
  alert_channel_name: string;
  alert_channel_id: number;
  created: number;
  alert_id: string;
  alert_channel_timezone_str: string;
  review_logs: ReviewLog[];
  image_b64: string;
  video_b64: string;
  video_file: string;
  s3_bucket: string;
  file_path: string;
  filename: string;
  media_url?: string;
  media_url_secondary?: string;
  video_available_yet?: boolean;
}
export interface AlertChannel {
  alert_channel_id: number;
  name: string;
  org_id: number;
  org_name: string;
  site_id: number;
  created: string;
  description: string;
  timezone_str: string;
  locale: string;
  meta: { [key: string]: any };
  type: string;
  default_confidence_threshold: number;
  sop: string;
  emails: MultiEmail[];
}
export interface AlertConfig {
  enabled: boolean;
  alert_config_id: number;
  alert_channel_id: number;
  sensor_id: number;
  class_label: string;
  alert_type: string;
  sensor_id_str: string;
  human_name: string;
  valid_days_and_times: string;
}
export interface GetAlertConfig {
  success: boolean;
  total_items: number;
  per_page: number;
  num_pages: number;
  page: number;
  alert_configs: AlertConfig[];
}
export interface GetLocale {
  success: boolean;
  total_items: number;
  per_page: number;
  num_pages: number;
  page: number;
  locales: Locale[];
}
export interface HealthTimelineCount {
  epoch_msecs: number;
  doc_count: number;
  site_count: number;
  indicators: { [key: string]: number };
}
export interface ReviewLog {
  created: number;
  log_username: string;
  review_status: string;
  content: string;
}
export interface Sensor {
  sensor_id: number;
  sensor_id_str: string;
  hub_id: number;
  site_id: number;
  enabled: boolean;
  config: { [key: string]: any };
  snapshot: CanvasImage;
  thumbnail: CanvasImage;
  human_name: string;
  meta: string;
  type: string;
}
export interface CanvasImage {
  width: number;
  height: number;
  stringb64: string;
}
export interface Classifier {
  key: number;
  name: string;
  enabled: boolean;
  description: string;
  rest_api_urls: string;
  class_labels: string[];
  meta: string;
  sensor_defaults: { [key: string]: any };
}
export interface Health {
  msg: Msg;
  success: boolean;
}
export interface Msg {
  sites: SiteHealth[];
}
export interface SiteHealth {
  health_indicator: string;
  site_id: number;
  sensors: SensorHealth[];
  hubs: HubHealth[];
}
export interface MultiEmail {
  key: Guid;
  email: string;
  parent_id: number;
}
export interface SensorHealth {
  sensor_overall_indicator: string;
  sensor_id: number;
  sensor_id_str: string;
}

export interface SensorHealthTimelineCount {
  epoch_msecs: number;
  doc_count: number;
  sensor_count: number;
  sensors: { [key: string]: { [key: string]: number } };
}
export interface HubHealth {
  site_health_indicator: string;
  hub_id: number;
  hub_id_str: string;
  msg_healthy: boolean;
  vpn_healthy: boolean;
}
export interface Hub {
  hub_id: number;
  hub_id_str: string;
  location: string;
  site_id: number;
  enabled: boolean;
  org_tree: number[];
  type: string;
}
export interface Activity {
  alert_counts: Count[];
  inference_counts: Count[];
}
export interface Count {
  epoch_msecs: number;
  count: number;
}
export interface Identity {
  username: string;
}
export interface GetAlerts {
  hitlimit: number;
  alert_data: Alert[];
  success: boolean;
  code: number;
  timefilter: number;
  msg?: string;
}

export interface MainMenu {
  navigator: boolean;
  reports: boolean;
  dashboard: boolean;
  settings: boolean;
}

export interface Site {
  site_id: number;
  name: string;
  enabled: boolean;
  billing_rate_id: number;
  org_tree: number[];
  type: string;
  product: string;
  meta: { [key: string]: any };
}
export interface Token {
  exp: number;
  fresh: boolean;
  iat: number;
  identity: Identity;
  jti: string;
  nbf: number;
  type: string;
  user_claims: UserClaims;
}
export interface UserClaims {
  first_name: string;
  last_name: string;
  user_id: number;
  channel_ids: number[];
  channel_roles: { [key: string]: string[] };
  channel_sites: { [key: string]: { [key: string]: any } };
  channel_sensors: { [key: string]: { [key: string]: any } };
  channel_details: { [number: string]: { [key: string]: any } };
  roles?: any;
}

export interface Locale {
  key: number;
  locale: string;
  element: string;
  mapping: { [key: string]: string };
  meta: string;
}
export interface GetLocale {
  success: boolean;
  total_items: number;
  per_page: number;
  num_pages: number;
  page: number;
  locales: Locale[];
}
export interface MediaResult {
  alert_id?: string;
  url?: string;
  video_filename?: string;
  b64string?: string;
  media_url?: string;
  media_url_secondary?: string;
  video_unavailable_yet?: boolean;
}
export interface UpdateResult {
  success: boolean;
  msg: string;
}
export interface audioRecordingResult {
  success: boolean;
  recordings: audioRecording[];
}
export interface sensorMetaResults {
  success: boolean;
  meta: { [key: string]: any };
}
export interface frontelVideoQueue {
  type: string;
  panel_serial_number: string;
  customer_account: string;
  ip: string;
  received_timestamp: string;
  video_index: string;
  cam_num: string;
  video_size: string;
  cam_name: string;
  in_progress: boolean;
  completed: boolean;
}

export interface amendedFrontelVideoQueue {
  panel_serial_number: string;
  customer_account: string;
  ip: string;
  video_index: string;
}

export interface frontelQueue {
  success: boolean;
  msg?: string;
  queue?: frontelVideoQueue[];
}
export interface audioRecording {
  id: number;
  description: string;
}

export class FunctionService {
  private authService: AuthService | undefined;
  private static ManageRestApiRoot = "/api/v1.0";
  private static StreamingUrl = "/stream/video";
  public locales: Locale[] = [];
  public setUrl(url: string) {
    if (url.includes("alert.staging.deepalert.ai") || url.includes("local")) {
      FunctionService.ManageRestApiRoot =
        "https://alert-rest.staging.deepalert.ai/api/v1.0";
      // "https://alert-rest.deepalert.ai/api/v1.0";
    } else if (
      url.includes("deepalert.ai") &&
      (url.startsWith("https://alert") || url.startsWith("http://alert"))
    ) {
      var slicedUrl = url.substring(0, url.indexOf("deepalert.ai"));
      FunctionService.ManageRestApiRoot =
        slicedUrl.replace("alert", "alert-rest") + "deepalert.ai/api/v1.0";
    } else if (
      url.includes("deepalert.io") &&
      (url.startsWith("https://alert") || url.startsWith("http://alert"))
    ) {
      var slicedUrl = url.substring(0, url.indexOf("deepalert.io"));
      FunctionService.ManageRestApiRoot =
        slicedUrl.replace("alert", "alert-rest") + "deepalert.io/api/v1.0";
    } else {
      FunctionService.ManageRestApiRoot =
        "https://alert-rest.staging.deepalert.ai/api/v1.0";
    }
  }

  public setStreamingUrl(url: string) {
    if (url.includes("staging") || url.includes("local")) {
      FunctionService.StreamingUrl =
        "https://stream.staging.deepalert.ai/video";
    } else if (url.includes("deepalert.ai")) {
      FunctionService.StreamingUrl = "https://stream.deepalert.ai/video";
    } else if (url.includes("deepalert.io")) {
      FunctionService.StreamingUrl = "https://stream.deepalert.io/video";
    }
  }

  public getStreamingUrl() {
    return FunctionService.StreamingUrl;
  }

  //##################
  //# VERSION CHECKING#
  //##################

  async checkVersion(): Promise<CheckVersionResult | any> {
    try {
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/min_version_check",
        {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      );
      const result = await (await res).json();
      result.code = (await res).status;
      return result as CheckVersionResult;
    } catch (err) {
      return err;
    }
  }
  //##################
  //# AUTH FUNCTIONS #
  //##################

  async authenticateUser(
    username: string,
    password: string,
  ): Promise<AuthResult | any> {
    try {
      const user = {
        username: username,
        password: password,
      };
      const res = fetch(FunctionService.ManageRestApiRoot + "/login", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });
      const result = await (await res).json();
      result.code = (await res).status;
      return result as AuthResult;
    } catch (err) {
      return err;
    }
  }

  async freshAuthenticateUser(
    username: string,
    password: string,
  ): Promise<AuthResult | any> {
    try {
      const user = {
        username: username,
        password: password,
      };
      const res = fetch(FunctionService.ManageRestApiRoot + "/fresh_login", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });
      const result = await (await res).json();
      result.code = (await res).status;
      return result as AuthResult;
    } catch (err) {
      return err;
    }
  }

  async authenticateUserOrg(
    org_id: number,
    access_token: string,
  ): Promise<AuthResult | any> {
    try {
      const org = {
        entry_org_id: org_id,
      };
      const res = fetch(FunctionService.ManageRestApiRoot + "/org_login", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
        body: JSON.stringify(org),
      });
      const result = await (await res).json();
      result.code = (await res).status;
      return result as AuthResult;
    } catch (err) {
      return err;
    }
  }

  async refreshAccessToken(refresh_token: string): Promise<AuthResult | any> {
    try {
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/refresh_access_token",
        {
          method: "post",
          headers: {
            Authorization: "Bearer " + refresh_token,
          },
        },
      );
      const result = await (await res).json();
      result.code = (await res).status;
      return result as AuthResult;
    } catch (err) {
      return err;
    }
  }

  async removeAccessToken(access_token: string): Promise<AuthResult | any> {
    try {
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/logout_access_token",
        {
          method: "delete",
          headers: {
            Authorization: "Bearer " + access_token,
          },
        },
      );
      const result = await (await res).json();
      result.code = (await res).status;
      return result as AuthResult;
    } catch (err) {
      return err;
    }
  }

  async removeRefreshToken(refresh_token: string): Promise<AuthResult | any> {
    try {
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/logout_refresh_token",
        {
          method: "delete",
          headers: {
            Authorization: "Bearer " + refresh_token,
          },
        },
      );
      const result = await (await res).json();
      result.code = (await res).status;
      return result as AuthResult;
    } catch (err) {
      return err;
    }
  }

  async getConfig(access_token: string): Promise<any> {
    try {
      const res = fetch(FunctionService.ManageRestApiRoot + "/fetch_config", {
        method: "get",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
      });
      const result = await (await res).json();
      result.code = (await res).status;
      return result;
    } catch (err) {
      return err;
    }
  }
  async getLocale(
    access_token: string,
    locale: string,
  ): Promise<Locale[] | any> {
    try {
      const request = JSON.stringify({
        locale: locale,
        verbose: true,
        paginate: false,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/list_locale", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as GetLocale;
      let ret = result.locales;
      return ret;
    } catch (err) {
      return err;
    }
  }

  async getStream(access_token: string, event_id: string): Promise<any> {
    try {
      const request = JSON.stringify({
        event_id: event_id,
      });
      let url = FunctionService.StreamingUrl + "_size?event_id=" + event_id;
      const res = fetch(url, {
        method: "get",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
      });
      let stream = (await (await res).json()) as string;
      return stream;
    } catch (err) {
      console.warn("GOT error:" + err);
      return err;
    }
  }
  async getHealthDetailForSite(
    access_token: string,
    site_id: number,
  ): Promise<any> {
    try {
      const request = JSON.stringify({
        site_id: site_id,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/site_health_detail",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        },
      );
      const result = (await (await res).json()) as any;
      let ret = {};
      if (result.success) {
        ret = result.msg[0];
      }
      return ret;
    } catch (err) {
      return err;
    }
  }
  async setMute(
    access_token: string,
    site_id: number,
    sensor_id: number,
    action: string,
    mute_mins: number,
  ): Promise<any> {
    //action is either "mute" or "unmute"
    try {
      let request = "";
      console.debug(
        "site_id: ",
        site_id,
        "sensor_id: ",
        sensor_id,
        "mute_mins: ",
        mute_mins,
        "action: ",
        action,
      );
      if (sensor_id != 0) {
        request = JSON.stringify({
          sensor_id: sensor_id,
          mute_mins: mute_mins,
          action: action,
        });
      } else {
        request = JSON.stringify({
          site_id: site_id,
          mute_mins: mute_mins,
          action: action,
        });
      }
      const res = fetch(FunctionService.ManageRestApiRoot + "/mute_control", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      let muteresult = (await (await res).json()) as string;
      return muteresult;
    } catch (err) {
      console.warn("GOT error:" + err);
      return err;
    }
  }
  async setMuteOld(
    access_token: string,
    alert_channel_ids: number[],
    sensor_id: number,
    action: string,
    mute_mins: number,
  ): Promise<any> {
    //action is either "mute" or "unmute"
    try {
      let channel_id_filter = "[";
      alert_channel_ids.forEach((alert_channel_id) => {
        channel_id_filter = channel_id_filter + alert_channel_id + ",";
      });
      channel_id_filter =
        channel_id_filter.substr(0, channel_id_filter.length - 1) + "]";
      let request = "";
      console.debug(
        "channel_id_filter: ",
        channel_id_filter,
        "sensor_id: ",
        sensor_id,
        "mute_mins: ",
        mute_mins,
        "action: ",
        action,
      );
      if (sensor_id != 0) {
        request = JSON.stringify({
          alert_channel_ids: channel_id_filter,
          sensor_id: sensor_id,
          mute_mins: mute_mins,
          action: action,
        });
      } else {
        request = JSON.stringify({
          alert_channel_ids: channel_id_filter,
          mute_mins: mute_mins,
          action: action,
        });
      }
      const res = fetch(FunctionService.ManageRestApiRoot + "/mute_control", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      let muteresult = (await (await res).json()) as string;
      return muteresult;
    } catch (err) {
      console.warn("GOT error:" + err);
      return err;
    }
  }
  async getMuteStatus(
    access_token: string,
    alert_channel_ids: number[],
  ): Promise<any> {
    //action is either "mute" or "unmute"
    try {
      let channel_id_filter = "[";
      alert_channel_ids.forEach((alert_channel_id) => {
        channel_id_filter = channel_id_filter + alert_channel_id + ",";
      });
      channel_id_filter =
        channel_id_filter.substr(0, channel_id_filter.length - 1) + "]";
      let request = "";

      if (channel_id_filter === "]") {
        channel_id_filter = "[]";
      }

      request = JSON.stringify({
        alert_channel_ids: channel_id_filter,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/mute_status", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      let muteresult = (await (await res).json()) as string;
      return muteresult;
    } catch (err) {
      console.warn("GOT error:" + err);
      return err;
    }
  }
  async getLatestVideoFromPreSignedUrl(
    access_token: string,
    alert_channel_id: number,
    track_id: string,
  ): Promise<string | undefined | any> {
    try {
      const request = JSON.stringify({
        alert_channel_id: alert_channel_id,
        track_id: track_id,
        include_media_url: true,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/fetch_latest_video",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        },
      );

      const vidresult = (await (await res).json()) as any;
      if (
        vidresult &&
        vidresult.video_data &&
        (vidresult.video_data.media_url ||
          vidresult.video_data.media_url_secondary)
      ) {
        return vidresult.video_data as MediaResult;
      } else {
        return;
      }
    } catch (err) {}
  }

  async getHealth(
    access_token: string,
    site_ids: number[],
    include_sensors: boolean,
  ): Promise<Health | any> {
    let site_id_filter = "[";
    site_ids.forEach((site_id) => {
      site_id_filter = site_id_filter + site_id + ",";
    });
    site_id_filter = site_id_filter.substr(0, site_id_filter.length - 1) + "]";

    if (site_id_filter === "]") {
      site_id_filter = "[]";
    }

    try {
      const request = JSON.stringify({
        site_ids: site_id_filter,
        include_sensors: include_sensors,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/site_health", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as any;

      return result;
    } catch (err) {
      return err;
    }
  }

  async checkUrlValidity(url: string): Promise<number> {
    try {
      const res = fetch(url, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const result = await await res;
      let res_code = result.status;
      return res_code as number;
    } catch (err) {
      console.warn(err);
      return 404;
    }
  }

  async getActivity(
    access_token: string,
    site_ids: number[],
    start_time_filter: number,
    bin_secs: number,
  ): Promise<any> {
    try {
      let site_id_filter = "[";
      site_ids.forEach((site_id) => {
        site_id_filter = site_id_filter + site_id + ",";
      });
      site_id_filter =
        site_id_filter.substr(0, site_id_filter.length - 1) + "]";

      const request = JSON.stringify({
        site_ids: site_id_filter,
        start_time_filter: start_time_filter,
        bin_secs: bin_secs,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/activity", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as any;

      return result;
    } catch (err) {
      console.warn("GOT error:" + err);
      return err;
    }
  }
  async getHealthTimeLineForSites(
    access_token: string,
    site_ids: number[],
    start_time_filter: number,
    bin_secs: number,
  ): Promise<any> {
    try {
      let site_id_filters = "[";
      site_ids.forEach((site_id) => {
        site_id_filters = site_id_filters + site_id + ",";
      });
      site_id_filters =
        site_id_filters.substr(0, site_id_filters.length - 1) + "]";
      const request = JSON.stringify({
        site_ids: site_id_filters,
        start_time_filter: start_time_filter,
        bin_secs: bin_secs,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/site_health_timeline",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        },
      );
      const result = (await (await res).json()) as any;

      return result;
    } catch (err) {
      console.warn("GOT error:" + err);
      return err;
    }
  }
  async getActivityForSensors(
    access_token: string,
    sensor_ids: number[],
    start_time_filter: number,
    bin_secs: number,
  ): Promise<any> {
    try {
      let sensor_id_filters = "[";
      sensor_ids.forEach((sensor_id) => {
        sensor_id_filters = sensor_id_filters + sensor_id + ",";
      });
      sensor_id_filters =
        sensor_id_filters.substr(0, sensor_id_filters.length - 1) + "]";
      const request = JSON.stringify({
        sensor_ids: sensor_id_filters,
        start_time_filter: start_time_filter,
        bin_secs: bin_secs,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/activity", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as any;

      return result;
    } catch (err) {
      console.warn("GOT error:" + err);
      return err;
    }
  }
  async getHealthTimeLineForSensor(
    access_token: string,
    site_id: number,
    sensor_id_str: string,
    start_time_filter: number,
    bin_secs: number,
  ): Promise<any> {
    try {
      const request = JSON.stringify({
        site_id: site_id,
        sensor_id_str: sensor_id_str,
        start_time_filter: start_time_filter,
        bin_secs: bin_secs,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/sensor_health_timeline",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        },
      );
      const result = (await (await res).json()) as any;

      return result;
    } catch (err) {
      console.warn("GOT error:" + err);
      return err;
    }
  }
  async updateAlertStatus(
    access_token: string,
    alert_ids: string[],
    status: string,
    note: string,
  ): Promise<any> {
    try {
      let alert_id_filters = '["';
      alert_ids.forEach((alert_id) => {
        alert_id_filters = alert_id_filters + alert_id + '","';
      });
      alert_id_filters =
        alert_id_filters.substr(0, alert_id_filters.length - 2) + "]";
      const request = JSON.stringify({
        alert_ids: alert_id_filters,
        status: status,
        note: note,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/alert_status", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });

      const result = (await (await res).json()) as any;

      return result;
    } catch (err) {
      console.warn("GOT error:" + err);
      return err;
    }
  }

  async emailAlertExport(
    access_token: string,
    alert_ids: string[],
  ): Promise<any> {
    try {
      let alert_id_filters = '["';
      alert_ids.forEach((alert_id) => {
        alert_id_filters = alert_id_filters + alert_id + '","';
      });
      alert_id_filters =
        alert_id_filters.substr(0, alert_id_filters.length - 2) + "]";
      const request = JSON.stringify({
        alert_ids: alert_id_filters,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/email_export", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as any;

      return result;
    } catch (err) {
      console.warn("GOT error:" + err);
      return err;
    }
  }

  async telegramAlertExport(
    access_token: string,
    alert_ids: string[],
  ): Promise<any> {
    try {
      let alert_id_filters = '["';
      alert_ids.forEach((alert_id) => {
        alert_id_filters = alert_id_filters + alert_id + '","';
      });
      alert_id_filters =
        alert_id_filters.substr(0, alert_id_filters.length - 2) + "]";
      const request = JSON.stringify({
        alert_ids: alert_id_filters,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/telegram_export",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        },
      );
      const result = (await (await res).json()) as any;

      return result;
    } catch (err) {
      console.warn("GOT error:" + err);
      return err;
    }
  }
  async talkbackTextToSpeech(
    access_token: string,
    sensor_id_str: string,
    tts_text: string,
  ): Promise<any> {
    try {
      const request = JSON.stringify({
        sensor_id_str,
        tts_text,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/talkback_tts", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UpdateResult;

      return result;
    } catch (err) {
      console.warn("GOT error:" + err);
      return err;
    }
  }

  async talkbackAudio(
    access_token: string,
    sensor_id_str: string,
    audio_b64: string,
  ): Promise<UpdateResult | any> {
    try {
      const request = JSON.stringify({
        sensor_id_str,
        audio_b64,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/talkback_audio", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UpdateResult;

      return result;
    } catch (err) {
      console.warn("GOT error:" + err);
      return err;
    }
  }

  async getSensorMeta(
    access_token: string,
    sensor_id_str: string,
  ): Promise<sensorMetaResults | any> {
    try {
      const request = JSON.stringify({
        sensor_id_str,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/list_sensor_meta",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        },
      );
      const result = (await (await res).json()) as sensorMetaResults;
      return result;
    } catch (err) {
      return err;
    }
  }

  async getFrontelQueue(
    access_token: string,
    frontelAccount: string,
    panelSerialNumber: string,
    ip: string,
  ): Promise<frontelQueue | any> {
    try {
      const request = JSON.stringify({
        frontel_account: frontelAccount,
        panel_serial_number: panelSerialNumber,
        ip_address: ip,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_frontel_queue",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        },
      );
      const result = (await (await res).json()) as frontelQueue;
      return result;
    } catch (err) {
      return err;
    }
  }

  async amendFrontelQueue(
    access_token: string,
    frontelAccount: string,
    panelSerialNumber: string,
    queue: string,
    ip: string,
  ): Promise<frontelQueue | any> {
    try {
      const request = JSON.stringify({
        frontel_account: frontelAccount,
        panel_serial_number: panelSerialNumber,
        ordered_list: queue,
        ip_address: ip,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/manage_frontel_queue",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        },
      );
      const result = (await (await res).json()) as frontelQueue;
      return result;
    } catch (err) {
      return err;
    }
  }

  async triggerFrontelDevice(
    access_token: string,
    frontelAccount: string,
    panelSerialNumber: string,
    deviceIndex: string,
    status: number,
    ip: string,
  ): Promise<any> {
    try {
      const request = JSON.stringify({
        frontel_account: frontelAccount,
        panel_serial_number: panelSerialNumber,
        device_index: deviceIndex,
        status: status,
        ip_address: ip,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/trigger_frontel_siren",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        },
      );
      const result = await (await res).json();
      return result;
    } catch (err) {
      return err;
    }
  }

  async getPreCannedAudio(
    access_token: string,
    sensor_id_str: string,
  ): Promise<audioRecordingResult | any> {
    try {
      const res = fetch(
        FunctionService.ManageRestApiRoot +
          "/talkback_precanned?sensor_id_str=" +
          sensor_id_str,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
        },
      );
      const result = (await (await res).json()) as audioRecordingResult;
      return result;
    } catch (err) {
      return err;
    }
  }

  async postPreCannedAudio(
    access_token: string,
    sensor_id_str: string,
    audio_id: number,
  ): Promise<UpdateResult | any> {
    try {
      const request = JSON.stringify({
        sensor_id_str,
        audio_id,
      });
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/talkback_precanned",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        },
      );
      const result = (await (await res).json()) as UpdateResult;

      return result;
    } catch (err) {
      return err;
    }
  }

  async setAlertForTraining(
    access_token: string,
    alert_ids: string[],
  ): Promise<any> {
    try {
      let alert_id_filters = '["';
      alert_ids.forEach((alert_id) => {
        alert_id_filters = alert_id_filters + alert_id + '","';
      });
      alert_id_filters =
        alert_id_filters.substr(0, alert_id_filters.length - 2) + "]";
      const request = JSON.stringify({
        alert_ids: alert_id_filters,
      });

      const res = fetch(
        FunctionService.ManageRestApiRoot + "/alert_flag_accuracy",
        {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        },
      );
      const result = (await (await res).json()) as any;

      return result;
    } catch (err) {
      console.warn("GOT error:" + err);
      return err;
    }
  }

  async addAlertNote(
    access_token: string,
    alert_id: string,
    status: string,
    note: string,
  ): Promise<any> {
    try {
      let alert_id_filters = '["';
      alert_id_filters = alert_id_filters + alert_id + '"' + "]";
      const request = JSON.stringify({
        alert_ids: alert_id_filters,
        status: status,
        note: note,
      });

      const res = fetch(FunctionService.ManageRestApiRoot + "/alert_status", {
        method: "put",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as any;

      return result;
    } catch (err) {
      console.warn("GOT error:" + err);
      return err;
    }
  }

  async getAlerts(
    access_token: string,
    start_time_filter: number,
    end_time_filter: number,
    channel_ids: number[],
    site_ids: number[],
    sensor_channel_ids: [number, number][],
    Total_sensor_channel_ids: [number, number][],
    statuses: string[],
    appStore: AppStore,
  ): Promise<GetAlerts> {
    //console.log("Getting alerts for channels :" + channel_ids);
    //console.log("Getting alerts for sites :" + site_ids);
    try {
      let channel_id_filter = "[";
      channel_ids.forEach((channel_id) => {
        channel_id_filter = channel_id_filter + channel_id + ",";
      });
      channel_id_filter =
        channel_id_filter.substr(0, channel_id_filter.length - 1) + "]";

      let site_id_filter = "[";
      site_ids.forEach((site_id) => {
        site_id_filter = site_id_filter + site_id + ",";
      });
      site_id_filter =
        site_id_filter.substr(0, site_id_filter.length - 1) + "]";

      let channel_and_sensor_filter = "[";
      sensor_channel_ids.forEach((sensor_channel_id) => {
        channel_and_sensor_filter =
          channel_and_sensor_filter +
          "(" +
          sensor_channel_id[0] +
          "," +
          sensor_channel_id[1] +
          "),";
      });
      channel_and_sensor_filter =
        channel_and_sensor_filter.substr(
          0,
          channel_and_sensor_filter.length - 1,
        ) + "]";

      // let excluded_sensor_channel_ids = Total_sensor_channel_ids.filter(
      //   (item, index) => !sensor_channel_ids.includes(item)
      // );
      let d = Total_sensor_channel_ids.map((x) => x.toString());
      let included_ids = sensor_channel_ids.map((x) => x.toString());
      let excluded_ids = d.filter((x) => !included_ids.includes(x));
      // let imArr = []
      let excluded_sensor_channel_ids = excluded_ids.map((x) =>
        x.split(",").map((x) => +x),
      );

      let channel_and_sensor_excl_filter = "[";
      excluded_sensor_channel_ids &&
        excluded_sensor_channel_ids.forEach((sensor_channel_id) => {
          if (excluded_sensor_channel_ids[0] === sensor_channel_id) {
            channel_and_sensor_excl_filter =
              channel_and_sensor_excl_filter +
              "(" +
              sensor_channel_id[0] +
              "," +
              sensor_channel_id[1] +
              ")";
          } else {
            channel_and_sensor_excl_filter =
              channel_and_sensor_excl_filter +
              ",(" +
              sensor_channel_id[0] +
              "," +
              sensor_channel_id[1] +
              ")";
          }
        });
      channel_and_sensor_excl_filter = channel_and_sensor_excl_filter + "]";

      let alert_state_filter = "[";
      statuses.forEach((status) => {
        alert_state_filter = alert_state_filter + "'" + status + "',";
      });
      alert_state_filter =
        alert_state_filter.substr(0, alert_state_filter.length - 1) + "]";

      //console.log("filter inc", included_ids);
      //console.log("filter exc", excluded_ids);
      //console.log("filter total", d);

      if (channel_and_sensor_filter === "]") {
        channel_and_sensor_filter = "[]";
      }

      let request;
      if (statuses.length > 0) {
        if (end_time_filter > 0) {
          if (start_time_filter > 0) {
            if (included_ids.length > excluded_ids.length) {
              request = JSON.stringify({
                start_time_filter: start_time_filter,
                end_time_filter: end_time_filter,
                channel_and_sensor_excl_filter: channel_and_sensor_excl_filter,
                site_id_filter: [],
                alert_state_filter: alert_state_filter,
                include_media_url: true,
              });
            } else {
              request = JSON.stringify({
                start_time_filter: start_time_filter,
                end_time_filter: end_time_filter,
                site_id_filter: [],
                channel_and_sensor_filter: channel_and_sensor_filter,
                alert_state_filter: alert_state_filter,
                include_media_url: true,
              });
            }
          } else {
            if (included_ids.length > excluded_ids.length) {
              request = JSON.stringify({
                end_time_filter: end_time_filter,
                site_id_filter: [],
                channel_and_sensor_excl_filter: channel_and_sensor_excl_filter,
                alert_state_filter: alert_state_filter,
                include_media_url: true,
              });
            } else {
              request = JSON.stringify({
                end_time_filter: end_time_filter,
                site_id_filter: [],
                channel_and_sensor_filter: channel_and_sensor_filter,
                alert_state_filter: alert_state_filter,
                include_media_url: true,
              });
            }
          }
        } else {
          if (start_time_filter > 0) {
            if (included_ids.length > excluded_ids.length) {
              request = JSON.stringify({
                start_time_filter: start_time_filter,
                site_id_filter: [],
                channel_and_sensor_excl_filter: channel_and_sensor_excl_filter,
                alert_state_filter: alert_state_filter,
                include_media_url: true,
              });
            } else {
              request = JSON.stringify({
                start_time_filter: start_time_filter,
                site_id_filter: [],
                channel_and_sensor_filter: channel_and_sensor_filter,
                alert_state_filter: alert_state_filter,
                include_media_url: true,
              });
            }
          } else {
            if (included_ids.length > excluded_ids.length) {
              request = JSON.stringify({
                site_id_filter: [],
                channel_and_sensor_excl_filter: channel_and_sensor_excl_filter,
                alert_state_filter: alert_state_filter,
                include_media_url: true,
              });
            } else {
              request = JSON.stringify({
                site_id_filter: [],
                channel_and_sensor_filter: channel_and_sensor_filter,
                alert_state_filter: alert_state_filter,
                include_media_url: true,
              });
            }
          }
        }
      } else {
        if (end_time_filter > 0) {
          if (start_time_filter > 0) {
            if (included_ids.length > excluded_ids.length) {
              request = JSON.stringify({
                start_time_filter: start_time_filter,
                end_time_filter: end_time_filter,
                site_id_filter: [],
                channel_and_sensor_excl_filter: channel_and_sensor_excl_filter,
                include_media_url: true,
              });
            } else {
              request = JSON.stringify({
                start_time_filter: start_time_filter,
                end_time_filter: end_time_filter,
                site_id_filter: [],
                channel_and_sensor_filter: channel_and_sensor_filter,
                include_media_url: true,
              });
            }
          } else {
            if (included_ids.length > excluded_ids.length) {
              request = JSON.stringify({
                end_time_filter: end_time_filter,
                site_id_filter: [],
                channel_and_sensor_excl_filter: channel_and_sensor_excl_filter,
                include_media_url: true,
              });
            } else {
              request = JSON.stringify({
                end_time_filter: end_time_filter,
                site_id_filter: [],
                channel_and_sensor_filter: channel_and_sensor_filter,
                include_media_url: true,
              });
            }
          }
        } else {
          if (start_time_filter > 0) {
            if (included_ids.length > excluded_ids.length) {
              request = JSON.stringify({
                start_time_filter: start_time_filter,
                site_id_filter: [],
                channel_and_sensor_excl_filter: channel_and_sensor_excl_filter,
                include_media_url: true,
              });
            } else {
              request = JSON.stringify({
                start_time_filter: start_time_filter,
                site_id_filter: [],
                channel_and_sensor_filter: channel_and_sensor_filter,
                include_media_url: true,
              });
            }
          } else {
            if (included_ids.length > excluded_ids.length) {
              request = JSON.stringify({
                site_id_filter: [],
                channel_and_sensor_excl_filter: channel_and_sensor_excl_filter,
                include_media_url: true,
              });
            } else {
              request = JSON.stringify({
                site_id_filter: [],
                channel_and_sensor_filter: channel_and_sensor_filter,
                include_media_url: true,
              });
            }
          }
        }
      }
      var token = await appStore?.authService.getAuthorisedToken();

      const res = fetch(FunctionService.ManageRestApiRoot + "/fetch_alerts", {
        method: "post",
        headers: {
          Accept: "application/json",
          // Authorization: "Bearer " + access_token,
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: request,
      });

      const result = (await (await res).json()) as GetAlerts;

      result.code = (await res).status;
      if (result.code === 200) {
        result.success = true;
      } else {
        result.success = false;
      }
      return result;
    } catch (err: any) {
      console.warn("Got error:");
      var ret = new impGetAlerts(0, [], false, 0, 0);
      return ret;
    }
  }
  translate(locale: string, element: string, key: string): string {
    if (!this.locales || (this.locales && this.locales.length == 0)) {
      this.updateLocale();
    }
    if (this.locales && this.locales.length && this.locales.length > 0) {
      let localemap = this.locales.find(
        (i) => i.locale == locale && i.element == element,
      );
      if (localemap) {
        const map = localemap.mapping;
        if (map.hasOwnProperty(key)) {
          return map[key];
        } else {
          return key;
        }
      } else {
        return key;
      }
    } else {
      return key;
    }
  }

  async fetchStreamingBaseUrl(
    access_token: string,
    sensor_id_str: string,
  ): Promise<any> {
    try {
      const res = fetch(
        FunctionService.ManageRestApiRoot +
          `/streaming_endpoint?sensor_id_str=${sensor_id_str}`,

        {
          method: "get",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            // "Content-Type": "application/json",
          },
        },
      );
      const result = (await (await res).json()) as any;
      return result;
    } catch (err) {
      return err;
    }
  }

  async updateLocale(): Promise<void> {
    if (this.authService) {
      var token = await this.authService.getAuthorisedToken();
      if (token) {
        var newlocales = await this.getLocale(token, "en_ZA");
        if (newlocales) {
          this.locales = newlocales;
        }
      }
    }
  }
  setAuthService(authSerevice: AuthService) {
    this.authService = authSerevice;
  }

  public formatDate(date: Date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var strTime = hours + ":" + minutes + ":" + seconds;
    return (
      date.getFullYear() +
      "/" +
      (date.getMonth() + 1) +
      "/" +
      date.getDate() +
      "  " +
      strTime
    );
  }

  async setUserSettings(
    access_token: string,
    strategy: string,
    theme: string,
  ): Promise<any> {
    let request = JSON.stringify({
      strategy: strategy,
      theme: theme,
    });
    try {
      const res = fetch(
        FunctionService.ManageRestApiRoot + "/save_user_settings",
        {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
          body: request,
        },
      );

      const result = await (await res).json();
      result.code = (await res).status;
      return result;
    } catch (err: any) {
      return err.message;
    }
  }
}
