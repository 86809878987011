import React, { useState } from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
const { Panel } = Collapse;

const AlertsForSite = ({
  siteId,
  site_name,
  message = [],
  count,
  time,
  red = false,
  children,
  item,
}) => {
  const [openPanel, setOpenPanel] = useState(null);
  const togglePanel = (key) => {
    setOpenPanel((prevKey) => (prevKey === key ? null : key));
  };

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      className="site-collapse-custom-collapse"
      style={{
        background: red ? "#6C0F02" : "#1f3161",
        borderRadius: 3,
        color: "white",
        alightItems: "center",
        marginRight: 3,
        fontWeight: 600,
        fontSize: 12,
        borderBottomColor: "red",
        borderBottomWidth: "4px",
      }}
      onChange={() => togglePanel(siteId)}
      expandIconPosition="end"
      activeKey={openPanel ? [openPanel] : []}
    >
      <Panel
        key={siteId}
        header={
          <div
            style={{ height: message.length ? 65 : 40, paddingBottom: 15 }}
            onClick={() => {
              item();
            }}
          >
            <p>{site_name}</p>
            {message.length > 0 && (
              <p
                style={{
                  marginBottom: 5,
                  padding: 0,
                  fontSize: 12,
                  color: "#d9d9d9",
                  fontWeight: "500",
                }}
              >
                <i>{message[message.length - 1]}</i>
              </p>
            )}
            <div
              style={{
                paddingBottom: 20,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                fontSize: 10,
              }}
            >
              <p>
                <i>Total Alerts: </i> {count}
              </p>{" "}
              {time && (
                <p style={{ marginLeft: 10 }}>
                  <i>Latest Alert: </i> {time}
                </p>
              )}
            </div>
          </div>
        }
      >
        {children}
      </Panel>
    </Collapse>
  );
};

export default AlertsForSite;
