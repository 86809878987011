// Some commonly used timezones
const COMMON_TIMEZONES = [
  "UTC",
  "GMT",
  "Africa/Harare",
  "Africa/Johannesburg",
  "Europe/London",
  "Asia/Shanghai",
  "Asia/Tokyo",
  "America/Detroit",
  "Asia/Calcutta",
  "Australia/Sydney",
  "",
];

const LOCALE = "en-US";

// Formatting dates using `toLocaleDateString` is expensive, because it has to look up the time zone information in a database
// Instead, we just create a cached formatter for each timezone that we encounter, so that we only have to do the lookup once per timezone.
const formatterMap = new Map<string, Intl.DateTimeFormat>();

function getFormatter(timeZone: string): Intl.DateTimeFormat | undefined {
  // Handle empty timezone case
  if (!timeZone) {
    timeZone = "";
  }
  let existingFormatter = formatterMap.get(timeZone);
  if (!existingFormatter) {
    try {
      existingFormatter = timeZone
        ? new Intl.DateTimeFormat(LOCALE, { timeZone })
        : new Intl.DateTimeFormat(LOCALE);
      formatterMap.set(timeZone, existingFormatter);
    } catch (error) {
      // If the timezone is invalid, we just return undefined and the resulting formatted date will be undefined as well
      console.warn(error);
      return undefined;
    }
  }
  return existingFormatter;
}

// Cache commonly used timezones (this is not a complete list, but covers all timezones in production as of March 2025)
for (const timeZone of COMMON_TIMEZONES) {
  getFormatter(timeZone);
}

export function formatDateToTimeZone(date: Date, timeZone: string) {
  return getFormatter(timeZone)?.format(date);
}
