import React from "react";

import { useStyles } from "../../styling/global/global";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Typography from "@material-ui/core/Typography";
import { Typography as AntTypography, Button } from "antd";
import { Input } from "antd";
import { Helpers } from "../../../services/Helpers";
import { AppStore } from "../../../stores/AppStore";
import { SiteHealth } from "../../../services/FunctionService";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import SensorStatsModal from "../../modals/SensorStatsModal/SensorStatsModal";
import MenuModal from "../../modals/MenuModal/MenuModal";
import Tooltip from "@material-ui/core/Tooltip";
import SiteHealthDetail from "../../modals/SiteHealthDetail/SiteHealthDetail";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Switch from "@material-ui/core/Switch";

const { Search } = Input;

const { Text } = AntTypography;

interface LeftDrawerProps {
  setSelectedChannels: (selected: number[]) => void;
  setSelectedSites: (selected: number[]) => void;
  setSelectedSensors: (selected: [number, number][]) => void;
  channel_details: { [number: string]: { [key: string]: any } };
  channel_sites: any;
  // channel_sites: { [key: string]: { [key: string]: any } };
  channel_sensors: { [key: string]: { [key: string]: any } };
  currenttheme: string;
  appStore: AppStore;
  health: SiteHealth[];
  selectedchannels: number[];
  selectedsites: number[];
  selectedsensors: [number, number][];
  roles: string[];
  confirmselection: boolean;
  selectionConfirmed: () => void;
  helpers: Helpers;
  handleMute: (
    mute_mins: number,
    site_id: number,
    sensor_id: number,
    alert_channel_id: number,
  ) => void;
  mutedchannels: number[];
  mutedsensors: [number, number, string][];
  handleStream: (sensor_id_str: string) => void;
}
const LeftDrawer: React.FC<LeftDrawerProps> = ({
  handleStream,
  mutedchannels,
  mutedsensors,
  handleMute,
  helpers,
  confirmselection,
  selectionConfirmed,
  roles,
  currenttheme,
  appStore,
  selectedchannels,
  selectedsites,
  selectedsensors,
  health,
  channel_sites,
  channel_sensors,
  channel_details,
  setSelectedSensors,
  setSelectedChannels,
  setSelectedSites,
}: LeftDrawerProps) => {
  const classes = useStyles();

  const [refresh, setRefresh] = React.useState(0);
  const [opensensorstats, setOpenSensorStats] = React.useState<boolean>(false);
  const [selectedsensoridstr, setSelectedSensorIdStr] =
    React.useState<string>("");
  const [selectedsensorid, setSelectedSensorId] = React.useState<number>(0);
  const [selectedsiteid, setSelectedSiteId] = React.useState<number>(0);
  const [selectedchannelid, setSelectedChannelId] = React.useState<number>(0);
  const [siteidforhealth, setSiteIdForHealth] = React.useState<number>(0);
  const [sitehealthdetail, setSiteHealthDetail] =
    React.useState<boolean>(false);
  const [openmodalmenu, setOpenModalMenu] = React.useState<boolean>(false);
  const [showstream, setShowStream] = React.useState<boolean>(false);
  const [showmute, setShowMute] = React.useState<boolean>(false);
  const [showstats, setShowStats] = React.useState<boolean>(false);
  const [ismuted, setIsMuted] = React.useState<boolean>(false);
  const [siteidopen, setSiteIdOpen] = React.useState<number>(0);
  const [nameformenu, setNameForMenu] = React.useState<string>("");
  const [statssiteid, setStatsSiteId] = React.useState<number>(0);
  const [unHealthySites, setUnHealthySites] = React.useState(false);

  const [searchText, setSearchText] = React.useState("");
  const onSearch: any = (value: any, _e: any) => {
    setSearchText(value);
  };

  //when click on select all checkbox
  const handleSelectAll = () => {
    if (selectedchannels.length === 0) {
      for (let key in channel_details) {
        selectedchannels.push(Number(key));
      }
      helpers.setSelectedChannels(selectedchannels);
      setSelectedChannels(selectedchannels);
    }
    if (selectedsites.length === 0) {
      for (let key in channel_sites) {
        let site = channel_sites[key];
        if (selectedsites.indexOf(site.id) < 0) {
          selectedsites.push(Number(site.id));
        }
      }
      helpers.setSelectedSites(selectedsites);
      setSelectedSites(selectedsites);
    }
    if (selectedsensors.length === 0) {
      var initselectedsensors: [number, number][] = [];
      for (let key in channel_sensors) {
        let sensors = channel_sensors[key];
        sensors.forEach((sensor: { id: any }) => {
          let x: [number, number] = [Number(key), sensor.id];
          initselectedsensors.push(x);
        });
      }
      helpers.setSelectedSensors(initselectedsensors);
      setSelectedSensors(initselectedsensors);
    }
  };

  //clicking on uselect all checkbox
  const handleUnselectAll = () => {
    let ch: number[] = [];
    setSelectedChannels(ch);
    let si: number[] = [];
    setSelectedSites(si);
    let sen: [number, number][] = [];
    setSelectedSensors(sen);
  };

  //Open and close site health detail modal popup
  const siteHealthDetail = (site_id: number) => {
    setSiteIdForHealth(site_id);
    setSiteHealthDetail(true);
  };
  const closeSiteHealthDetail = () => {
    setSiteHealthDetail(false);
  };

  //Single menu implementation to save to brower resources
  const clickMenu = (
    stats_site_id: number,
    site_id: number,
    sensor_id: number,
    alert_channel_id: number,
    sensor_id_str: string,
    stream: boolean,
    mute: boolean,
    stats: boolean,
    ismuted: boolean,
    name: string,
  ) => {
    setSelectedSensorIdStr(sensor_id_str);
    setSelectedSensorId(sensor_id);
    setSelectedSiteId(site_id);
    setStatsSiteId(stats_site_id);
    setSelectedChannelId(alert_channel_id);
    setShowStream(stream);
    setShowMute(mute);
    setShowStats(stats);
    setIsMuted(ismuted);
    setNameForMenu(name);
    setOpenModalMenu(true);
  };

  const closeModalMenu = () => {
    setSelectedSensorIdStr("");
    setSelectedSensorId(0);
    setSelectedSiteId(0);
    setSelectedChannelId(0);
    setShowStream(false);
    setShowMute(false);
    setShowStats(false);
    setIsMuted(false);
    setOpenModalMenu(false);
  };

  //Select / de-select channels
  const handleChannelChange = (key: number) => {
    if (selectedchannels.indexOf(key) >= 0) {
      delete selectedchannels[selectedchannels.indexOf(key)];
      selectedsensors.forEach((pair, index) => {
        if (pair[0] == key) {
          delete selectedsensors[index];
        }
      });
    } else {
      selectedchannels.push(key);
      for (let cs in channel_sites) {
        let channelsite = channel_sites[cs];
        if (Number(cs) == key) {
          if (selectedsites.indexOf(channelsite.id) < 0) {
            selectedsites.push(channelsite.id);
          }
        }
      }
      let sensors = channel_sensors[key];
      sensors.forEach((sensor: any) => {
        let x: [number, number] = [Number(key), sensor.id];
        selectedsensors.push(x);
      });
    }
    selectedchannels = normaliseArray(selectedchannels);
    selectedsites = normaliseArray(selectedsites);
    selectedsensors = normaliseArray(selectedsensors);
    setSelectedSites(selectedsites);
    setSelectedChannels(selectedchannels);
    setSelectedSensors(selectedsensors);
    helpers.setSelectedSites(selectedsites);
    helpers.setSelectedChannels(selectedchannels);
    helpers.setSelectedSensors(selectedsensors);
    setRefresh(new Date().getTime());
  };

  //Select / de-select sites
  const handleSiteChange = (key: number) => {
    console.debug("call:  handleSiteChange", key);
    if (selectedsites.indexOf(key) >= 0) {
      delete selectedsites[selectedsites.indexOf(key)];
      selectedchannels.forEach((channel_id) => {
        if (key == channel_sites[channel_id].id) {
          delete selectedchannels[selectedchannels.indexOf(channel_id)];
          selectedsensors.forEach((pair, index) => {
            if (pair[0] == channel_id) {
              delete selectedsensors[index];
            }
          });
        }
      });
    } else {
      selectedsites.push(key);
      for (let cs in channel_sites) {
        let channelsite = channel_sites[cs];
        if (channelsite.id == key) {
          if (selectedchannels.indexOf(Number(cs)) < 0) {
            selectedchannels.push(Number(cs));
            let sensors = channel_sensors[cs];
            sensors.forEach((sensor: any) => {
              let x: [number, number] = [Number(cs), sensor.id];
              selectedsensors.push(x);
            });
          }
        }
      }
    }
    selectedchannels = normaliseArray(selectedchannels);
    selectedsites = normaliseArray(selectedsites);
    selectedsensors = normaliseArray(selectedsensors);
    setSelectedChannels(selectedchannels);
    setSelectedSites(selectedsites);
    setSelectedSensors(selectedsensors);
    helpers.setSelectedSites(selectedsites);
    helpers.setSelectedChannels(selectedchannels);
    helpers.setSelectedSensors(selectedsensors);
    setRefresh(new Date().getTime());
  };

  //Select / de-select sensors
  const handleSensorChange = (channel_id: number, sensor_id: number) => {
    var deleted = false;
    selectedsensors.forEach((pair, index) => {
      if (pair[0] == channel_id && pair[1] == sensor_id) {
        delete selectedsensors[index];
        deleted = true;
      }
    });
    if (!deleted) {
      selectedsensors.push([channel_id, sensor_id]);
      if (selectedchannels.indexOf(channel_id) < 0) {
        selectedchannels.push(channel_id);
      }
      for (let cs in channel_sites) {
        let channelsite = channel_sites[cs];
        if (Number(cs) == channel_id) {
          if (selectedsites.indexOf(channelsite.id) < 0) {
            selectedsites.push(channelsite.id);
          }
        }
      }
    }
    selectedchannels = normaliseArray(selectedchannels);
    selectedsites = normaliseArray(selectedsites);
    selectedsensors = normaliseArray(selectedsensors);
    setSelectedSites(selectedsites);
    setSelectedChannels(selectedchannels);
    setSelectedSensors(selectedsensors);
    helpers.setSelectedSites(selectedsites);
    helpers.setSelectedChannels(selectedchannels);
    helpers.setSelectedSensors(selectedsensors);
    setRefresh(new Date().getTime());
  };
  //remove undefineds from array
  const normaliseArray = (input: any[]) => {
    let newarray: any[] = [];
    input.forEach((element: any | undefined) => {
      if (element) {
        newarray.push(element);
      }
    });
    return newarray;
  };
  // open sensor stats modal popup
  const closeSensorStats = () => {
    setOpenSensorStats(false);
    setSelectedSensorIdStr("");
    setSelectedSensorId(0);
    setSelectedSiteId(0);
  };
  //close sensor stats modal popup
  const openSensorStats = (
    sensor_id_str: string,
    sensor_id: number,
    site_id: number,
  ) => {
    setSelectedSensorIdStr(sensor_id_str);
    setSelectedSensorId(sensor_id);
    setSelectedSiteId(site_id);
    setOpenSensorStats(true);
  };
  //open own expansion panel
  const clickSiteOpen = (site_id: number) => {
    if (siteidopen !== site_id) {
      setSiteIdOpen(site_id);
    } else {
      setSiteIdOpen(0);
    }
  };
  //display tree, only populate current site that is open, drawback is that only one site can be open at a time, but this saves on resources
  const unhealthy_siteIds =
    health &&
    health
      .filter((sitehealth) => sitehealth?.health_indicator === "error")
      .map((site) => site.site_id);
  const getTree = (
    channel_sites: { [key: string]: { [key: string]: any } },
    channel_sensors: { [key: string]: { [key: string]: any } },
    channel_details: { [number: string]: { [key: string]: any } },
  ) => {
    var sites: { [key: string]: any }[] = [];
    for (let key in channel_details) {
      let channel = channel_details[key];
      channel["key"] = key;
      let site = channel_sites[key];
      let sensors = channel_sensors[key];
      let thissite = sites.find((element) => element.id == site.id);
      if (!thissite) {
        channel["sensors"] = [];
        sensors.forEach((sensor: any) => {
          channel["sensors"].push(sensor);
        });
        site["channels"] = [];
        site["channels"].push(channel);
        sites.push(site);
      } else {
        channel["sensors"] = [];
        sensors.forEach((sensor: any) => {
          channel["sensors"].push(sensor);
        });
        thissite["channels"].push(channel);
      }
    }
    let tree_disp: React.ReactNode[] = [];
    // can only deselect site if supervisor
    var deselect = false;
    if (roles.includes("web_alert_camera_filter")) {
      deselect = true;
    }
    //can only
    var mute = false;
    if (
      roles.includes("web_mute_controls") ||
      roles.includes("web_mute_one_hour")
    ) {
      mute = true;
    }
    let siteList = sites;

    if (unHealthySites && unhealthy_siteIds.length > 0) {
      siteList = sites.filter(
        (site) => unhealthy_siteIds.indexOf(site.id) > -1,
      );
    }
    const sortedSites = siteList
      .filter((item: any) =>
        item.name.toLowerCase().includes(searchText.toLowerCase()),
      )
      .sort((a, b): number => {
        // const sortedSites = siteList.sort((a, b): number => {
        if (b.name.toLowerCase() > a.name.toLowerCase()) {
          return -1;
        }
        if (b.name.toLowerCase() < a.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    sortedSites.forEach((site) => {
      let checked = false;
      if (selectedsites.indexOf(Number(site.id)) >= 0) {
        checked = true;
      }
      var fill = "";
      var opacity = "0.95";
      var tooltip: React.ReactNode[] = [];
      if (health && health.length > 0) {
        let sitehealth = health.find((x) => x.site_id == site.id);
        if (sitehealth?.health_indicator == "ok") {
          fill = "#3f54a5";
          opacity = "0.75";
        }
        if (sitehealth?.health_indicator == "warning") {
          fill = "#b4d5fa";
        }
        if (sitehealth?.health_indicator == "error") {
          fill = "#ee4623";
        }
        if (sitehealth?.health_indicator == "unknown") {
          fill = "#D0D3D4";
        }

        for (let hubkey in sitehealth?.hubs) {
          let hub = sitehealth?.hubs[Number(hubkey)];
          if (hub) {
            tooltip.push("Hub " + hub.hub_id_str + " info:");
            tooltip.push(<br></br>);
            if (hub.msg_healthy === true) {
              tooltip.push("Message:");
              tooltip.push(
                <SentimentSatisfiedAltIcon></SentimentSatisfiedAltIcon>,
              );
            } else if (hub.msg_healthy === false) {
              tooltip.push("Message:");
              tooltip.push(
                <SentimentVeryDissatisfiedIcon></SentimentVeryDissatisfiedIcon>,
              );
            } else {
            }
            // tooltip.push("Message:");
            // hub.msg_healthy ? tooltip.push(<SentimentSatisfiedAltIcon></SentimentSatisfiedAltIcon>) : tooltip.push(<SentimentVeryDissatisfiedIcon></SentimentVeryDissatisfiedIcon>);
            if (hub.vpn_healthy === true) {
              tooltip.push("VPN:");
              tooltip.push(
                <SentimentSatisfiedAltIcon></SentimentSatisfiedAltIcon>,
              );
            } else if (hub.vpn_healthy === false) {
              tooltip.push("VPN:");
              tooltip.push(
                <SentimentVeryDissatisfiedIcon></SentimentVeryDissatisfiedIcon>,
              );
            } else {
            }
            // tooltip.push("VPN:");
            // hub.vpn_healthy ? tooltip.push(<SentimentSatisfiedAltIcon></SentimentSatisfiedAltIcon>) : tooltip.push(<SentimentVeryDissatisfiedIcon></SentimentVeryDissatisfiedIcon>);
            tooltip.push("Site:");
            if (hub.site_health_indicator == "error") {
              tooltip.push(
                <SentimentVeryDissatisfiedIcon></SentimentVeryDissatisfiedIcon>,
              );
            } else if (hub.site_health_indicator == "warning") {
              tooltip.push(
                <SentimentDissatisfiedIcon></SentimentDissatisfiedIcon>,
              );
            } else if (hub.site_health_indicator == "ok") {
              tooltip.push(
                <SentimentSatisfiedAltIcon></SentimentSatisfiedAltIcon>,
              );
            } else {
              tooltip.push(
                <RadioButtonUncheckedIcon></RadioButtonUncheckedIcon>,
              );
            }
            tooltip.push(<Divider />);
          }
        }
      }

      let site_icon: React.ReactNode[] = [];
      let bln_site_muted = true;
      site["channels"].forEach((channel: { [x: string]: any[] }) => {
        if (mutedchannels.indexOf(Number(channel.key)) < 0) {
          bln_site_muted = false;
        }
      });
      if (bln_site_muted) {
        site_icon.push(
          <path
            key="muted-icon"
            d="M6 2v6h.01L6 8.01 10 12l-4 4 .01.01H6V22h12v-5.99h-.01L18 16l-4-4 4-3.99-.01-.01H18V2H6zm10 14.5V20H8v-3.5l4-4 4 4zm-4-5l-4-4V4h8v3.5l-4 4z"
            fill="#ee4623"
          />,
        );
      } else {
        site_icon.push(
          <circle
            key="circle-icon"
            cx="10"
            cy="10"
            r="13"
            fill={fill}
            fill-opacity={opacity}
          ></circle>,
        );
        site_icon.push(
          <path
            key="path-icon-1"
            d="M17 6.3V1H14V3.6L10 0L0 9H3V17H9V11H11V17H17V9H20L17 6.3ZM15 15H13V9H7V15H5V7.19L10 2.69L15 7.19V15Z"
            fill="#FFFFFF"
            fill-opacity="0.87"
          />,
        );
        site_icon.push(
          <path
            key="path-icon-2"
            d="M8 7H12C12 5.9 11.1 5 10 5C8.9 5 8 5.9 8 7Z"
            fill="#FFFFFF"
            fill-opacity="0.87"
          />,
        );
      }
      let expand: React.ReactNode[] = [];
      let siteopen: React.ReactNode[] = [];
      if (siteidopen === site.id) {
        expand.push(
          <ExpandLessIcon
            className={classes.icon_white}
            onClick={() => clickSiteOpen(site.id)}
          />,
        );
        siteopen = getSite(site.id);
      } else {
        expand.push(
          <ExpandMoreIcon
            className={classes.icon_white}
            onClick={() => clickSiteOpen(site.id)}
          />,
        );
      }
      let dotmenu: React.ReactNode[] = [];
      if (mute) {
        dotmenu.push(
          <IconButton
            onClick={() =>
              clickMenu(
                0,
                site.id,
                0,
                0,
                "",
                false,
                mute,
                false,
                bln_site_muted,
                site.name,
              )
            }
          >
            <MoreVertIcon
              fontSize="small"
              className={
                currenttheme == "dark" ? classes.icon_white : classes.icon_blue
              }
            />
          </IconButton>,
        );
      }
      tree_disp.push(
        <div
          key={site.id}
          className={
            currenttheme == "dark" ? classes.darkleft : classes.lightleft
          }
        >
          <div className={classes.expansionbar}>
            <Tooltip placement="left" title={tooltip}>
            <svg
                width="38"
                height="38"
                viewBox="-10 -10 38 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => siteHealthDetail(Number(site.id))}
                style={{ marginLeft: 5, width: 130}}
              >
                <g opacity="0.87">{site_icon}</g>
              </svg>
            </Tooltip>
            <Checkbox
              className={classes.icon_white}
              icon={
                <CheckBoxOutlineBlankIcon
                  className={classes.icon_white}
                  fontSize="small"
                />
              }
              checkedIcon={
                <CheckBoxIcon className={classes.icon_white} fontSize="small" />
              }
              checked={checked}
              onChange={() => handleSiteChange(Number(site.id))}
              disabled={!deselect}
            />
            <Typography className={classes.site_label}>
              {site.name ? site.name.replaceAll("_", "  ") : ""}
            </Typography>
            {dotmenu}
            {expand}
            <div className={classes.space_horisontal}></div>
            <div className={classes.space_horisontal}></div>
          </div>
          <div className={classes.lightleft}>{siteopen}</div>
        </div>,
      );
    });
    return tree_disp;
  };
  //populate THE open site (only one allowed)
  const getSite = (site_id: number) => {
    var sites: { [key: string]: any }[] = [];
    for (let key in channel_details) {
      let channel = channel_details[key];
      channel["key"] = key;
      let site = channel_sites[key];
      let sensors = channel_sensors[key];
      let thissite = sites.find((element) => element.id == site.id);
      if (!thissite) {
        channel["sensors"] = [];
        sensors.forEach((sensor: any) => {
          channel["sensors"].push(sensor);
        });
        site["channels"] = [];
        site["channels"].push(channel);
        sites.push(site);
      } else {
        channel["sensors"] = [];
        sensors.forEach((sensor: any) => {
          channel["sensors"].push(sensor);
        });
        thissite["channels"].push(channel);
      }
    }
    let site_disp: React.ReactNode[] = [];
    let site = sites.find((element) => element.id == site_id);
    if (site) {
      var deselect = false;
      if (roles.includes("web_alert_camera_filter")) {
        deselect = true;
      }
      var mute = false;
      if (
        roles.includes("web_mute_controls") ||
        roles.includes("web_mute_one_hour")
      ) {
        mute = true;
      }
      site["channels"].forEach((channel: { [x: string]: any }) => {
        let channel_disp: React.ReactNode[] = [];
        channel["sensors"].forEach((sensor: any) => {
          let sensorchecked = false;
          let x: [number, number, string] = [
            Number(channel.key),
            sensor.id,
            "",
          ];
          selectedsensors.forEach((pair) => {
            if (pair[0] == x[0] && pair[1] == x[1]) {
              sensorchecked = true;
            }
          });
          var fill = "";
          var opacity = "0.95";
          if (health && health.length > 0) {
            let sitehealth = health.find((x) => x.site_id == site!.id);
            let sensors = sitehealth?.sensors;
            let sensorhealth = sensors?.find((x) => x.sensor_id == sensor.id);
            if (sensorhealth?.sensor_overall_indicator == "ok") {
              fill = "#3f54a5";
              opacity = "0.75";
            }
            if (sensorhealth?.sensor_overall_indicator == "warning") {
              fill = "#b4d5fa";
            }
            if (sensorhealth?.sensor_overall_indicator == "error") {
              fill = "#ee4623";
            }
            if (sensorhealth?.sensor_overall_indicator == "unknown") {
              fill = "#D0D3D4";
            }
          }
          let sensor_icon: React.ReactNode[] = [];
          let bln_sensor_muted = false;
          let muted_until = "";
          mutedsensors.forEach((muted) => {
            if (
              muted.length === 3 &&
              x.length === 3 &&
              muted[0] === x[0] &&
              muted[1] === x[1]
            ) {
              sensor_icon.push(
                <path
                  d="M6 2v6h.01L6 8.01 10 12l-4 4 .01.01H6V22h12v-5.99h-.01L18 16l-4-4 4-3.99-.01-.01H18V2H6zm10 14.5V20H8v-3.5l4-4 4 4zm-4-5l-4-4V4h8v3.5l-4 4z"
                  fill="#ee4623"
                />,
              );
              bln_sensor_muted = true;
              let now = new Date();
              let until_date = Date.parse(muted[2]);
              var diff = (until_date - now.getTime()) / 1000;
              diff /= 60;
              let min = Math.abs(Math.round(diff));
              if (min === 1) {
                muted_until = min + " minute left";
              } else {
                muted_until = min + " minutes left";
              }
            }
          });
          if (sensor_icon.length === 0) {
            sensor_icon.push(
              <circle
                cx="11"
                cy="11"
                r="14"
                fill={fill}
                fill-opacity={opacity}
              ></circle>,
            );
            sensor_icon.push(
              // <path
              //   d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"
              //   fill="#15325F"
              //   fill-opacity="0.87"
              // />,
              <path
                fill={"white"}
                d="M18.7533 8.94444C18.6788 8.90646 18.595 8.88841 18.5106 8.89222C18.4263 8.89604 18.3446 8.92158 18.2743 8.96611L16.2 10.2806V8.88889C16.2 8.65314 16.1017 8.42705 15.9266 8.26035C15.7516 8.09365 15.5142 8 15.2667 8H5.93333C5.6858 8 5.4484 8.09365 5.27337 8.26035C5.09833 8.42705 5 8.65314 5 8.88889V15.1111C5 15.3469 5.09833 15.573 5.27337 15.7397C5.4484 15.9063 5.6858 16 5.93333 16H15.2667C15.5142 16 15.7516 15.9063 15.9266 15.7397C16.1017 15.573 16.2 15.3469 16.2 15.1111V13.7222L18.2743 15.0394C18.3514 15.0871 18.4415 15.112 18.5333 15.1111C18.6571 15.1111 18.7758 15.0643 18.8633 14.9809C18.9508 14.8976 19 14.7845 19 14.6667V9.33333C18.9994 9.25337 18.9762 9.17503 18.9328 9.10659C18.8893 9.03814 18.8273 8.98213 18.7533 8.94444ZM15.2667 15.1111H5.93333V8.88889H15.2667V15.1111ZM18.0667 13.8361L16.2 12.6511V11.3489L18.0667 10.1667V13.8361Z"
              />,
            );
          }

          channel_disp.push(
            <div className={classes.expansioncontent}>
              <div className={classes.space_horisontal}></div>
              <div className={classes.shelf_icon}>
                <Tooltip placement="top" title={muted_until}>
                  <svg
                    width="38"
                    height="38"
                    viewBox="-10 -10 38 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.87">{sensor_icon}</g>
                  </svg>
                </Tooltip>
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    // color="default"
                    style={{ color: "white" }}
                    checked={sensorchecked}
                    onChange={() =>
                      handleSensorChange(Number(channel.key), Number(sensor.id))
                    }
                    disabled={!deselect}
                  />
                }
                label={
                  // <Typography className={classes.full_label}>
                  //   {sensor.human_name}
                  // </Typography>
                  <Text
                    className={classes.full_label}
                    style={{ width: 150, marginRight: 50, marginBottom: 3 }}
                    ellipsis={{ tooltip: sensor.human_name } as any}
                  >
                    {sensor.human_name}
                  </Text>
                }
              />
              <span className={classes.sidemenu_camera}>
                <IconButton
                  onClick={() =>
                    clickMenu(
                      site_id,
                      0,
                      sensor.id,
                      Number(channel.key),
                      sensor.sensor_id_str,
                      site!.stream,
                      mute,
                      true,
                      bln_sensor_muted,
                      sensor.human_name,
                    )
                  }
                >
                  <MoreVertIcon
                    fontSize="small"
                    className={classes.icon_blue}
                  />
                </IconButton>
              </span>
            </div>,
          );
        });

        let channelchecked = false;
        if (selectedchannels.indexOf(Number(channel.key)) >= 0) {
          channelchecked = true;
        }

        if (site!["channels"].length > 1) {
          let channel_icon: React.ReactNode[] = [];
          let bln_channel_muted = false;
          if (mutedchannels.indexOf(Number(channel.key)) >= 0) {
            channel_icon.push(
              <path
                d="M6 2v6h.01L6 8.01 10 12l-4 4 .01.01H6V22h12v-5.99h-.01L18 16l-4-4 4-3.99-.01-.01H18V2H6zm10 14.5V20H8v-3.5l4-4 4 4zm-4-5l-4-4V4h8v3.5l-4 4z"
                fill="#ee4623"
              />,
            );
            bln_channel_muted = true;
          }
          if (channel_icon.length === 0) {
            channel_icon.push(
              <path
                d="M7.58 4.08L6.15 2.65C3.75 4.48 2.17 7.3 2.03 10.5h2c.15-2.65 1.51-4.97 3.55-6.42zm12.39 6.42h2c-.15-3.2-1.73-6.02-4.12-7.85l-1.42 1.43c2.02 1.45 3.39 3.77 3.54 6.42zM18 11c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2v-5zm-6 11c.14 0 .27-.01.4-.04.65-.14 1.18-.58 1.44-1.18.1-.24.15-.5.15-.78h-4c.01 1.1.9 2 2.01 2z"
                fill="#15325F"
                fill-opacity="0.87"
              />,
            );
          }
          let dotmenu: React.ReactNode[] = [];
          if (mute) {
            dotmenu.push(
              <IconButton
                onClick={() =>
                  clickMenu(
                    0,
                    0,
                    0,
                    Number(channel.key),
                    "",
                    false,
                    mute,
                    false,
                    bln_channel_muted,
                    channel.name,
                  )
                }
              >
                <MoreVertIcon fontSize="small" className={classes.icon_blue} />
              </IconButton>,
            );
          }
          site_disp.push(
            <>
              <div className={classes.expansioncontent}>
                <div className={classes.shelf_icon}>
                  <svg
                    width="38"
                    height="38"
                    viewBox="-10 -10 38 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.87">{channel_icon}</g>
                  </svg>
                </div>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      color="default"
                      checked={channelchecked}
                      onChange={() => handleChannelChange(Number(channel.key))}
                      disabled={!deselect}
                    />
                  }
                  label={
                    <Typography className={classes.full_label}>
                      {channel.name}
                    </Typography>
                  }
                />
                <span className={classes.sidemenu_channel}>{dotmenu}</span>
              </div>
              {channel_disp}
            </>,
          );
        } else {
          site_disp.push(<>{channel_disp}</>);
        }
      });
    }
    return site_disp;
  };
  var tree: React.ReactNode[] = [];
  if (channel_sites && channel_sensors && channel_details) {
    tree.push(getTree(channel_sites, channel_sensors, channel_details));
    tree.push(<div></div>);
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUnHealthySites(event.target.checked);
  };

  var topbar: React.ReactNode[] = [];
  var refreshicon: React.ReactNode[] = [];
  if (confirmselection) {
    refreshicon.push(
      <Tooltip title="Apply" aria-label="add">
        <div className={classes.pointer} onClick={selectionConfirmed}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 10H2v2h12v-2zm0-4H2v2h12V6zM2 16h8v-2H2v2zm19.5-4.5L23 13l-6.99 7-4.51-4.5L13 14l3.01 3 5.49-5.5z"
              fill={currenttheme == "dark" ? "#FFFFFF" : "#15325F"}
              fillOpacity="0.9"
            />
          </svg>
        </div>
      </Tooltip>,
    );
  }
  topbar.push(
    <div className={classes.leftAppBar}>
      <div className={classes.shelf_item_top}>
        <Tooltip title="Select all" aria-label="add">
          <div
            style={{ display: "flex", alignItems: "center" }}
            className={classes.pointer}
            onClick={handleSelectAll}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.53 11.06L15.47 10l-4.88 4.88-2.12-2.12-1.06 1.06L10.59 17l5.94-5.94z"
                fill={currenttheme == "dark" ? "#FFFFFF" : "#15325F"}
                fillOpacity="0.9"
              />
              <path
                d="M19 4H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H5V6h14v12z"
                fill={currenttheme == "dark" ? "#FFFFFF" : "#15325F"}
                fillOpacity="0.9"
              />
            </svg>
            <div style={{ color: "white" }}>All</div>
          </div>
        </Tooltip>
        <Tooltip title="Unselect all" aria-label="add">
          <div
            style={{ display: "flex", alignItems: "center" }}
            className={classes.pointer}
            onClick={handleUnselectAll}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 4H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H5V6h14v12z"
                fill={currenttheme == "dark" ? "#FFFFFF" : "#15325F"}
                fillOpacity="0.9"
              />
            </svg>
            <div style={{ color: "white", marginRight: 10 }}>None</div>
          </div>
        </Tooltip>
        <Tooltip title="Unhealthy sites" aria-label="add">
          <FormControlLabel
            control={
              <Switch
                checked={unHealthySites}
                size="small"
                onChange={handleChange}
                name="unhealthy sites"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            }
            label="unhealthy"
            style={{ color: "white", zIndex: 2 }}
          />
        </Tooltip>

        {refreshicon}
      </div>
    </div>,
  );

  return (
    <div
      className={
        currenttheme == "dark"
          ? classes.darkleftbackground
          : classes.lightleftbackground
      }
    >
      {topbar}
      <div style={{ padding: "0px 3px", width: "100%", paddingBottom: 10 }}>
        <Search
          style={{ width: "100%", borderRadius: 20 }}
          placeholder="filter by site name"
          allowClear
          onSearch={onSearch}
          enterButton={
            <Button
              type="primary"
              style={{ backgroundColor: "#ff4d4f", borderColor: "#ff4d4f" }}
            >
              Filter
            </Button>
          }
        />
      </div>
      {tree}
      <SiteHealthDetail
        site_id={siteidforhealth}
        appStore={appStore}
        open={sitehealthdetail}
        onClose={closeSiteHealthDetail}
      ></SiteHealthDetail>
      <SensorStatsModal
        currenttheme={currenttheme}
        appStore={appStore}
        open={opensensorstats}
        sensor_id_str={selectedsensoridstr}
        sensor_id={selectedsensorid}
        site_id={selectedsiteid}
        onClose={closeSensorStats}
      ></SensorStatsModal>
      <MenuModal
        name={nameformenu}
        handleMute={(
          minutes: number,
          site_id: number,
          sensor_id: number,
          alert_channel_id: number,
        ) => handleMute(minutes, site_id, sensor_id, alert_channel_id)}
        handleStream={(sensor_id_str: string) => handleStream(sensor_id_str)}
        handleStats={(
          sensor_id_str: string,
          sensor_id: number,
          site_id: number,
        ) => openSensorStats(sensor_id_str, sensor_id, site_id)}
        theme={currenttheme}
        open={openmodalmenu}
        sensor_id_str={selectedsensoridstr}
        sensor_id={selectedsensorid}
        site_id={selectedsiteid}
        stats_site_id={statssiteid}
        alert_channel_id={selectedchannelid}
        onClose={closeModalMenu}
        stream={showstream}
        mute={showmute}
        muted={ismuted}
        stats={showstats}
        appStore={appStore}
      ></MenuModal>
    </div>
  );
};

export default LeftDrawer;
