import React from "react";
import ReactDOM from "react-dom";
import { App } from "./core/App";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import { AuthService } from "./services/AuthService";
import { FunctionService } from "./services/FunctionService";
import { Helpers } from "./services/Helpers";
import { AppStore } from "./stores/AppStore";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";
import { Communication } from "./services/Communication";
import theme from "./theme";
const helpers = new Helpers();
const authService = new AuthService();
let url = window.location.href;
url =
  url === "https://alert.uat.deepalert.ai/"
    ? "https://alert.deepalert.ai/"
    : url === "http://alert.uat.deepalert.ai/"
    ? "http://alert.deepalert.ai/"
    : url === "https://alert.uat.staging.deepalert.ai/"
    ? "https://alert.staging.deepalert.ai/"
    : url === "http://alert.uat.staging.deepalert.ai/"
    ? "http://alert.staging.deepalert.ai/"
    : url === "https://alert.uat.preprod.deepalert.ai/"
    ? "https://alert.preprod.deepalert.ai/"
    : url === "http://alert.uat.preprod.deepalert.ai/"
    ? "http://alert.preprod.deepalert.ai/"
    : url;

console.debug("$URL: ", url);
const functionService = new FunctionService();
functionService.setUrl(url);
functionService.setStreamingUrl(url);
authService.setFunctionService(functionService);
functionService.setAuthService(authService);
helpers.setAuthService(authService);
helpers.setFunctionService(functionService);
const communication = new Communication(authService, url);
const appStore = new AppStore(
  functionService,
  authService,
  helpers,
  communication,
  "1.11.0",
);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App
        helpers={helpers}
        appStore={appStore}
        communication={communication}
      />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
